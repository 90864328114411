import React, { useEffect, useRef, useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/pages/Faq.scss"

import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"

export default function Faq() {
  const intl = useIntl();
  const [hiddenTabs, setHiddenTabs] = useState([]);

  const question0Ref = useRef(null);
  const question1Ref = useRef(null);
  const question2Ref = useRef(null);
  const question3Ref = useRef(null);

  const handleTab = (event)=> {
    if (!(question0Ref.current && question1Ref.current && question2Ref.current && question3Ref.current)) return null;
    const detail = event.target.parentElement;
    const detailId = detail.dataset.tab;

    hiddenTabs.forEach((hidden) => {
      if (hidden.dataset.tab !== detailId) hidden.removeAttribute("open");
    });
  };

  useEffect(() => {
    if (question0Ref.current && question1Ref.current && question2Ref.current && question3Ref.current) {
      setHiddenTabs([
        question0Ref.current,
        question1Ref.current,
        question2Ref.current,
        question3Ref.current
      ]);

    }
  }, [question0Ref, question1Ref, question2Ref, question3Ref]);

  return (
    <Layout>
      <Seo title="FAQ" />
      <main className="faq">
        <h1 className="faq__title">
          {intl.formatMessage({ id: "faq-title" })}
        </h1>
        <div className="faq__content">
          <details className="faq__question" ref={question0Ref} data-tab="1">
            <summary className="faq__question-title" onClick={handleTab}>
              {intl.formatMessage({ id: "faq-title-one" })}
            </summary>
            <div className="faq__question-answer">
              {intl.formatMessage({ id: "faq-text-one" })}
            </div>
          </details>
          <details className="faq__question" ref={question1Ref} data-tab="2">
            <summary className="faq__question-title" onClick={handleTab}>
              {intl.formatMessage({ id: "faq-title-two" })}
            </summary>
            <div className="faq__question-answer">
              {intl.formatMessage({ id: "faq-text-two" })}
            </div>
          </details>
          <details className="faq__question" ref={question2Ref} data-tab="3">
            <summary className="faq__question-title" onClick={handleTab}>
              {intl.formatMessage({ id: "faq-title-three" })}
            </summary>
            <div className="faq__question-answer">
              {intl.formatMessage({ id: "faq-text-three" })}
            </div>
          </details>
          <details className="faq__question" ref={question3Ref} data-tab="4">
            <summary className="faq__question-title" onClick={handleTab}>
              {intl.formatMessage({ id: "faq-title-four" })}
            </summary>
            <div className="faq__question-answer">
              {intl.formatMessage({ id: "faq-text-four" })}
            </div>
          </details>
        </div>
      </main>
    </Layout>
  )
}
